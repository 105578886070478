import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0];

export const dictionary = {
		"/(homepage)": [~13,[3]],
		"/(default)/__": [~5,[2]],
		"/(default)/contact": [~6,[2]],
		"/(default)/news": [~7,[2]],
		"/(default)/news/[slug]": [~8,[2]],
		"/(default)/practice": [~9,[2]],
		"/(default)/privacy-policy": [~10,[2]],
		"/(default)/projects": [~11,[2]],
		"/(default)/projects/[slug]": [~12,[2]],
		"/(default)/[slug]": [~4,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';